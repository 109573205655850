.logos{
  width: 100%;
  padding-bottom:20px ;
  display: flex;
  justify-content: flex-start;
}
.logo--github {
  margin-left: 10px;
  width: 32px;
  height: auto;
}
.logo--linkedIn {
  width: auto;
  height: 32px;
}
