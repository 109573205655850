.links {
  width: fit-content;
  height: 100px;

  display: flex;
  align-items: center;
}

.links__list {
  padding-left: 0;
  border-bottom: 2px solid var(--accent-color);
  display: flex;
  list-style: none;
  padding-bottom: 1rem;
}

.links__item {
  padding-right: 100px;
}

.links__item--anchor {
  font-size: 16px;
  transition: .2s ease-in-out;
  color: var(--text-color);
  text-decoration: none;
  font-weight: 700;
}
.links__item--active {
  color: var(--accent-color);
}

.links__item--last {
  padding-right: 0;
}


.links__item--anchor:hover{
  transition: .2s ease-in-out;
  color: var(--accent-color);
}

@media (max-width:990px) {
  .links__item--anchor {
    font-size: 16px;
  }

  .links__item {
    padding-right: 10vw;
  }

  .links__item--last {
    padding-right: 0;
  }
  
}

@media (max-width:430px) {
  .links__item--anchor {
    font-size: 14px;
  }
}

@media (max-width:360px) {
  .links__item--anchor {
    font-size: 12px;
  }
}