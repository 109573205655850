.app{
  padding-top: 3rem;
  min-height: 100vh;
  background-color: var(--bg-color);
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app__container {
  width: min(80vw,1000px);
  display: flex;
  justify-content: center;
}

.app__body{
  padding-top: 1rem;
}

@media (max-width:990px) {
  .app{
    display: flex;
  flex-direction: column;
  align-items: center;
  }
}