.resume{
    display: flex;
    justify-content: center;
}

.resume__image{
    width: min(70vw,750px);
    height: auto;
    margin: 2rem 0 5rem 0rem;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
}

@media (max-width:950px) {
    .resume__image{
        width: 90vw;
        
    }
}