.projects{
    margin: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.projects_divider{
    margin: 3rem 0;
    width: 100%;
    height: 2px;
    border-radius: 100%;
    background-color: var(--accent-color);
}