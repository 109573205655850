.contactMe{
    margin-bottom: 2rem;
}
.contactMe__form{
    width: 50vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

