
.home__title{
    font-size: 32px;
    color: var(--text-color);
}
.home__paragraph{
    text-align: justify;
    padding-top:2rem ;
    font-size: 16px;
    color: var(--text-color);
}

.home__images{
    padding-top: 2rem;
    display: flex;
    justify-content: space-around;
}

.images__container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.imagesContainer__image{
    width: 200px;
    height: auto;
    object-fit: cover;
}

.imagesContainer__caption{
    margin: 1rem;
    font-size: 15px;
}

@media (max-width:950px) {
    .home__images{
        flex-direction: column;
        align-items: center;
    }

    .imagesContainer__image{
        width: 80vw;
        height: auto;
        object-fit: cover;
    }
}