.projectCard{
    width: 100%;
    display: flex;
}

.projectCard__image--container{
    width: 350px;
    flex-shrink: 0;
}

.projectCard__image{
    border-radius: 2px;
    width: 100%;
    height: auto;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
}

.projectCard__information{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content:center;
    padding-left: 3rem;
}


.projectCard__information--title{
    transition: .2s ease;
    margin-bottom: 1rem;
    font-size: 32px;
    display: inline-block;
}
.projectCard__information--title--link:hover{
    transition: .2s ease;
    color: var(--accent-color);
}



.projectCard__information--stack{
    /* font-size: ; */
}

.projectCard__information--description{
    width: 100%;
    text-align: justify;
    line-height: 20px;
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, .5);
}

.projectCard__externalLinks{
    padding-top: 2rem;
    display: flex;
    justify-content: space-around;
}

.stackFeatures__container{
    display: flex;
    /* flex-direction: column; */
    justify-content:space-around;
}

.stackFeatures__Title{
    text-align: left;
    font-weight: 700;
    font-size: 20px;
    /* padding-right: 2rem; */
    padding-top: 1rem;
}

.stackFeatures__List{
    /* padding-right: 2rem; */
    list-style: none;
    text-indent: -1em;
}

.stackFeatures__item{
    line-height: 20px;
    font-size: 16px;
    display: inline-block;
    text-align: start;
    color: var(--text--color);
    padding: 0 0 .2rem 0;
    font-weight: 300;
}

.stackFeatures__bullet{
    line-height: 20px;
    font-size: 16px;
    color: var(--accent-color);
}

.externalLink{
    transition:.2s ease-in;
    font-weight: 500;
    text-decoration: none;
    color: white;
    background-color: var(--accent-color);
    border-radius: 30px;
    padding: 1rem;
    flex-shrink: 0;
    flex-grow: 0;
}

.externalLink--site{
    display: inline-block;
}

.externalLink:hover{
    transition:.2s ease-in;
    transform: scale(1.1);
    background-color: var(--bg--color);
    border: 1px solid var(--accent-color);
    color: var(--accent-color);
}

@media (max-width:1020px) {
    .projectCard{
        transform: scale(.8);
    }
}
@media (max-width:950px) {
    .projectCard{
        transform: scale(1);
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
    }

    .projectCard__information{
        width: 90%;
    }

    .projetCard__information__titleContainer{
        width: 90%;
        text-align: center;
    }

    .projectCard__information--description{
        width: 90%;
    }

    .projectCard__image--container{
        width: 90vw ;
        flex-shrink: 0;
    }
}