.info {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}
.info__credentials--name{
  font-size: 36px;
  color: var(--text-color);
}

.info__credentials--email{
  font-size: 16px;
  color: grey;
}

@media (max-width:450px) {
  .info__credentials--name{
    font-size: 32px;
  }
  .info__credentials--email{
    font-size: 16px;
  }
  /* .info__credentials--name{
    font-size: 20px;
    color: var(--text-color);
  }
  
  .info__credentials--email{
    font-size: 10px;
    color: grey;
  } */
}